/* You can add global styles to this file, and also import other style files */

@import './variables';

.main {
  width: 100%;
}
.images-row {
  display: flex;
  flex-wrap: nowrap;  /* Отключаем перенос строк */
  overflow-x: auto;   /* Включаем горизонтальную прокрутку */
  gap: 10px;
  width: 100%;        /* Ширина контейнера */

  /* Стили изображений внутри */
  .person-image {
      min-width: 100px;  /* Минимальная ширина изображения */
      height: 100px;
      object-fit: cover;
      cursor: pointer;
  }
}

.person-image {
  height: 100px;
  min-width: 100px;  /* Обязательно добавьте ширину или минимальную ширину */
  cursor: pointer;
  object-fit: cover;
}


  .display-flex {
    display: flex;
  }

  .flex-center {
    justify-content: center;
    align-items: center;
  }

  .custom-context-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .custom-context-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-context-menu ul li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .custom-context-menu ul li:hover {
    background-color: #f0f0f0;
  }
  .justify-center {
    justify-content: center;
  }
  .direction-column {
    flex-direction: column;
  }



  .ngx-ic-overlay {
    width: 100% !important;
  }